import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCollectiveContext } from 'context/CollectiveContext'

function PendingMembershipRequestNotice() {
  const { collective } = useCollectiveContext()

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="rounded-md bg-green-100 px-4 py-3">
        <p className="text-sm font-medium text-green-800">{`Thank you for requesting to join. Your membership request is pending.`}</p>
      </div>

      <div className="bg-white p-4 rounded-lg w-1/2 space-y-2 relative border border-gray-200">
        <p className="font-medium text-sm text-gray-700">{`What's Next?`}</p>

        <p className="font-light text-sm text-gray-700">
          {`The DAO members will review your request. If accepted, you'll become a member of ${collective.name}.`}
        </p>

        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="absolute top-1 right-2 text-gray-400"
          size="lg"
        />
      </div>
    </div>
  )
}

export default PendingMembershipRequestNotice
