import Thumbnail from 'components/Thumbnail'
import { BigNumber, parseEther } from 'lib/BigInt'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useFlag } from 'hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import { Collective } from 'graphql/generated'
import { ZERO_BN } from 'lib/collectives/helpers'
import TextField, {
  IProps as TextFieldProps
} from 'components/common/TextField'
import { twMerge } from 'tailwind-merge'

type TEvent = ChangeEvent<HTMLInputElement>
interface IProps extends TextFieldProps {
  initialValueInWei: BigNumber
  onChangeInWei: CallbackWithParam<BigNumber>
  onValidityChange?: CallbackWithParam<boolean>
  decimals?: number
  minWei?: BigNumber
  maxWei?: BigNumber
  symbol?: string
  type?: 'ETH' | 'TOKEN'
  estimatorFn?: CallbackWithParam<BigNumber, string>
  formatUnits?: boolean
  collective?: Pick<Collective, 'thumb' | 'name'>
}

function EtherInputField({
  onValidityChange,
  initialValueInWei,
  onChangeInWei,
  decimals = 18,
  minWei = ZERO_BN,
  maxWei,
  symbol = 'ETH',
  type = 'ETH',
  estimatorFn,
  formatUnits = true,
  collective,
  ...rest
}: IProps) {
  const spotPrice = useFlag('ETH_USD_PRICE', 3000)

  const [value, setValue] = useState<string>(
    formatUnits ? initialValueInWei.formatEther() : String(initialValueInWei)
  )

  const [error, setError] = useState<Maybe<string>>()
  useEffect(() => {
    onValidityChange?.(!error)
  }, [onValidityChange, error])

  const onChange = (e: TEvent) => {
    try {
      const stringValue = e.target.value
      setValue(stringValue)
      const valueInWei = new BigNumber(
        formatUnits ? parseEther(stringValue, decimals).toString() : stringValue
      )
      onChangeInWei(valueInWei)

      if (minWei !== null) {
        if (valueInWei.lt(minWei)) {
          setError(`Amount too low`)
          return
        }
      }
      if (maxWei) {
        if (valueInWei.gt(maxWei)) {
          setError(`Amount too high`)
          return
        }
      }

      setError(null)
    } catch (ex) {
      setError(`Invalid amount`)
    }
  }
  const onBlur = () => {
    if (error) {
      return
    }

    setValue(String(Number(Number(value.replace(/,/g, '')).toFixed(18))))
  }
  const onMaxClick = () => {
    if (!maxWei) {
      return
    }

    onChange({
      // @ts-ignore
      target: {
        value: formatUnits ? maxWei.formatEther(2, decimals) : String(maxWei)
      }
    })
  }

  const estimate = useMemo(
    () =>
      type === 'ETH'
        ? `~$${(Number(value) * spotPrice).toFixed(2)}`
        : estimatorFn?.(parseEther(value, decimals)),
    [type, value, estimatorFn, spotPrice, decimals]
  )

  return (
    <TextField
      horizantalFullWidth
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      postContent={
        <div className="min-w-30 flex items-center">
          {!!maxWei && (
            <span
              onClick={onMaxClick}
              className="cursor-pointer bg-gray-100 py-[5px] px[10px] rounded-[10px] font-light text-xs mr-[10px] hover:bg-gray-200"
            >
              MAX
            </span>
          )}

          <div
            className={twMerge(
              'flex items-center space-x-1',
              'border-l-gray-300 border-l-[1px] pl-[10px]'
            )}
          >
            {type === 'ETH' ? (
              <FontAwesomeIcon icon={faEthereum} className="text-gray-500" />
            ) : collective ? (
              <Thumbnail
                src={collective.thumb}
                initials={collective.name.substring(0)}
                className="h-6 w-6 rounded-full overflow-hidden shadow-md"
              />
            ) : null}
            <p className="text-gray-500 font-normal text-sm">{symbol}</p>
          </div>

          {!!estimate && (
            <div className="ml-[7px]">
              <p className="text-gray-500 font-normal text-sm">{`= ${estimate}`}</p>
            </div>
          )}
        </div>
      }
      error={error || undefined}
      {...rest}
    />
  )
}

export default EtherInputField
