import Popover from 'components/common/Popover'
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons'
import Button from 'components/Button'
import { Collective } from 'graphql/generated'
import Typography from 'components/common/Typography'
import TrackableClick from 'components/common/TrackableClick'
import LinkShareContent from '../../common/Share/LinkShareContent'
import { useBreakpoint } from 'hooks/useBreakpoint'

type TCollective = Pick<Collective, 'id' | 'publicUrl' | 'name'>

interface IProps {
  collective: TCollective
}

function CollectiveUserInvitePopover({ collective }: IProps) {
  const { isMobile } = useBreakpoint()
  return (
    <Popover
      placement="bottom-start"
      showDismissButton={true}
      from={(ref, toggle, visible) => {
        return (
          <TrackableClick
            event="Invite Button Clicked"
            props={{
              collectiveId: collective.id,
              collectiveName: collective.name
            }}
            trackAll
          >
            <Button
              onClick={toggle}
              size="sm"
              label={isMobile ? '' : 'Invite'}
              icon={faUserPlus}
              color={visible ? 'lightgray' : 'white'}
              ref={ref}
              showFocusRing={false}
            />
          </TrackableClick>
        )
      }}
    >
      <LinkShareContent
        titleContent={
          <Typography className="mb-4">
            Invite new members to your DAO &#127881;
          </Typography>
        }
        trackEventProps={{
          collectiveId: collective.id,
          collectiveName: collective.name
        }}
        url={collective.publicUrl}
      />
    </Popover>
  )
}

export default CollectiveUserInvitePopover
