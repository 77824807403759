import { useCollectiveContext } from 'context/CollectiveContext'
import {
  CollectiveMembershipRequestStatus,
  CollectiveTokenType
} from 'graphql/generated'
import ExternalCollectiveJoinNotice from './ExternalCollectiveJoinNotice'
import NotAcceptingNewMembersNotice from './NotAcceptingNewMembersNotice'
import OpenToContributionsNotice from './OpenToContributionsNotice'
import PendingMembershipRequestNotice from './PendingMembershipRequestNotice'
import RequestToJoinNotice from './RequestToJoinNotice'

function NoticeRouter() {
  const { collective } = useCollectiveContext()

  if (collective.governanceType === CollectiveTokenType.EXTERNAL) {
    return <ExternalCollectiveJoinNotice />
  } else if (!collective.parameters.gateDeposits) {
    return <OpenToContributionsNotice />
  } else {
    if (
      collective.membershipRequest?.status &&
      [
        CollectiveMembershipRequestStatus.PENDING,
        CollectiveMembershipRequestStatus.PROPOSED
      ].includes(collective.membershipRequest?.status)
    ) {
      return <PendingMembershipRequestNotice />
    } else if (!collective.parameters.enableNewMembershipRequests) {
      return <NotAcceptingNewMembersNotice />
    } else {
      return (
        <RequestToJoinNotice
          membershipRequestStatus={collective.membershipRequest}
        />
      )
    }
  }
}

export default NoticeRouter
