import { twMerge } from 'tailwind-merge'
import { getChainIdForNetwork } from 'lib/collectives/helpers'
import { useWalletCheck } from 'hooks/collectives/useWalletCheck'
import Typography from 'components/common/Typography'
import { Collective } from 'graphql/generated'
import { useSetChain } from '@web3-onboard/react'
import EtherscanLink from './EtherscanLink'

interface IProps {
  collective: Pick<Collective, 'network' | 'id'>
  hideCorrectWallet?: boolean
  className?: string
}

function WalletCheckInfo({ collective, className }: IProps) {
  const {
    walletHasAccess,
    wrongWalletNetwork,
    walletsThatHaveAccessToCollective
  } = useWalletCheck(collective)
  const [, setChain] = useSetChain()

  const onClickChangeNetwork = () =>
    setChain({ chainId: getChainIdForNetwork(collective.network) })

  if (!walletHasAccess && walletsThatHaveAccessToCollective?.length) {
    return (
      <div className={className}>
        <Typography color="error" size="sm" fontWeight="light">
          The connected wallet does not have access to this DAO. Please connect
          one of the following wallets to continue:
        </Typography>

        <ul className="mt-2 marker:text-red-500 list-disc pl-6">
          {walletsThatHaveAccessToCollective.map(u => (
            <li key={u.address}>
              <EtherscanLink
                shortAddress
                className="text-red-500 hover:text-red-700 text-sm"
                address={u.address}
              />
            </li>
          ))}
        </ul>
      </div>
    )
  } else if (wrongWalletNetwork) {
    return (
      <div
        className={twMerge(className, 'cursor-pointer')}
        onClick={onClickChangeNetwork}
      >
        <Typography color="red" size="sm" component={'span'}>
          The connected wallet is using the wrong network for this DAO.
          <br />
          Click here to switch to the {collective.network} network.
        </Typography>
      </div>
    )
  }

  return null
}

export default WalletCheckInfo
