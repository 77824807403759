import classes from 'classnames'
import Sticky from 'components/Sticky/Sticky'
import Thumbnail from 'components/Thumbnail'
import { Collective, CollectiveMember } from 'graphql/generated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxArchive, faHome } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Link from 'next/link'
import ScrollToTop from './ScrollToTop'
import { useRouter } from 'next/router'
import { StyledSelect } from 'components/common/Select'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { useBetaFeatureFlag, useIsLoggedIn, useWindowDimensions } from 'hooks'
import { useCollectiveContext } from 'context/CollectiveContext'
import CollectiveUserInvitePopover from 'components/Collectives/CollectiveUserInvitePopover/CollectiveUserInvitePopover'
import StyledTooltip from 'components/Tooltip/Tooltip'

interface IProps {
  collective: Pick<
    Collective,
    'id' | 'thumb' | 'name' | 'activeProposals' | 'publicUrl' | 'archived'
  > & { membership?: Maybe<Pick<CollectiveMember, 'approved'>> }
  indexPageMode?: boolean
}

type TDropdownOptionsType = {
  label: string
  value: string
  icon?: IconProp
  badge?: number | string
}

//to make sure Home is matched later for the selected value below

function CollectivePageHeader({ collective, indexPageMode }: IProps) {
  const HomeLinkTag = indexPageMode ? ScrollToTop : Link
  const { push, asPath } = useRouter()
  const handleDropdownUrlChange = (url: string) => {
    push(collective.publicUrl + url)
  }
  const { demoMode } = useCollectiveContext()
  const isLoggedIn = useIsLoggedIn()

  const { allowedAccess: allowForumAccess } = useBetaFeatureFlag(
    `ENABLE_DAO_FORUMS`,
    {
      allowForInternalCollectives: true,
      allowForCollectives: ['My First DAO', 'DAO']
    }
  )

  const dropdownOptions: TDropdownOptionsType[] = useMemo(() => {
    const options = [
      { label: 'Home', value: '/', icon: faHome },
      {
        label: 'Proposals',
        value: '/proposals',
        badge: collective.activeProposals
      },
      { label: 'Members', value: '/members' },
      { label: 'Events', value: '/events' }
    ]

    if (allowForumAccess) {
      options.push({ label: 'Forum', value: '/discussionBoard' })
    }
    return options
  }, [allowForumAccess, collective.activeProposals])

  const screen = useWindowDimensions()
  const isMobile =
    typeof window !== 'undefined' && !!screen.width && screen.width < 768 //md: =768px

  if (demoMode) {
    return null
  }

  return (
    <Sticky
      offset={isLoggedIn && !isMobile ? 63 : 0} // logged in navbar (and mobile views, always) stays sticky, too; otherwise no need to compensate
      className="mb-4 border-b border-gray-100 z-100 flex flex-row flex-wrap items-center justify-between px-4"
      unstuckClasses=""
      stuckClasses="bg-white pinned"
    >
      <div className="flex flex-row space-x-2 items-center truncate py-2 mr-2">
        <HomeLinkTag
          className="hover:underline"
          href={`${collective.publicUrl}`}
        >
          <Thumbnail
            src={collective.thumb}
            initials={collective.name.substring(0)}
            className={classes(
              'h-10 w-10 rounded-full overflow-hidden',
              indexPageMode && 'hidden pinned_visible'
            )}
          />
        </HomeLinkTag>

        <HomeLinkTag
          className="hover:underline"
          href={`${collective.publicUrl}`}
        >
          <h1 className="text-lg text-gray-800">{collective.name}</h1>
        </HomeLinkTag>

        {collective.archived && (
          <div className="mr-2">
            <StyledTooltip
              arrow
              title="This DAO has been archived. It's no longer operating or accepting new members."
            >
              <div>
                <span className="px-2 py-1 text-red-800 text-sm font-medium rounded-full bg-red-100 border-red-200 border">
                  <FontAwesomeIcon
                    icon={faBoxArchive}
                    size={'1x'}
                    className="mr-1"
                  />
                  Archived
                </span>
              </div>
            </StyledTooltip>
          </div>
        )}

        {isLoggedIn &&
          !collective.archived &&
          !!collective.membership?.approved && (
            <div className="mr-2">
              <CollectiveUserInvitePopover collective={collective} />
            </div>
          )}
      </div>

      <div className="sm:hidden py-2">
        <StyledSelect<TDropdownOptionsType>
          minWidth={150}
          placeholder="Select a Screen"
          value={dropdownOptions.find(
            option =>
              asPath === option.value ||
              (asPath.includes(option.value) && option.value !== '/')
          )}
          options={dropdownOptions}
          onChange={v => (v ? handleDropdownUrlChange(v?.value) : noop)}
        />
      </div>

      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {dropdownOptions.map(option => (
            <Link
              className={classes(
                asPath === option.value ||
                  (asPath.includes(option.value) && option.value !== '/')
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'whitespace-nowrap py-5 px-1 border-b-2 font-medium text-sm'
              )}
              href={option.value}
              key={option.value}
            >
              {option.icon ? (
                <FontAwesomeIcon icon={option.icon} />
              ) : (
                option.label
              )}

              {!!option.badge && (
                <span
                  className={classes(
                    asPath.endsWith(option.value)
                      ? 'bg-blue-100 text-blue-600'
                      : 'bg-gray-100 text-gray-900',
                    'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                  )}
                >
                  {option.badge}
                </span>
              )}
            </Link>
          ))}
        </nav>
      </div>
    </Sticky>
  )
}

export default CollectivePageHeader
