import BigNumber from 'bignumber.js'
import { WalletType } from 'graphql/generated'
import { useCallback } from 'react'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'

export const useUpdateCollectiveTokenOwnership = () => {
  const { collectiveDemo, updateDemoCache } = useCollectiveDemoContext()

  const updateCollectiveTokenOwnership = useCallback(
    (amountInWei: BigNumber) => {
      if (!collectiveDemo) {
        return
      }

      const newCollectiveDemo = { ...collectiveDemo }

      const ethWallet = newCollectiveDemo.wallets.find(
        wallet => wallet.type === WalletType.ETHEREUM
      )

      if (ethWallet) {
        ethWallet.balance = new BigNumber(ethWallet.balance)
          .plus(new BigNumber(amountInWei.formatEther()))
          .toString()
      }

      const token = newCollectiveDemo.tokens[0]

      if (token) {
        token.totalSupply = new BigNumber(token.totalSupply)
          .plus(amountInWei.times(newCollectiveDemo.parameters.exchangeRate))
          .toString()

        newCollectiveDemo.treasuryBalance = new BigNumber(
          newCollectiveDemo.treasuryBalance
        )
          .plus(amountInWei.toNumber())
          .toString()

        if (newCollectiveDemo.membership) {
          newCollectiveDemo.membership.totalTokens = new BigNumber(
            newCollectiveDemo.membership.totalTokens
          )
            .plus(amountInWei.times(newCollectiveDemo.parameters.exchangeRate))
            .toString()

          newCollectiveDemo.membership.percentOfTotalTokenSupply = Number(
            new BigNumber(newCollectiveDemo.membership.totalTokens)
              .times(100)
              .div(new BigNumber(token.totalSupply))
              .toNumber()
              .toFixed(2)
          )
        }
      }

      updateDemoCache(newCollectiveDemo)
    },
    [collectiveDemo, updateDemoCache]
  )

  return updateCollectiveTokenOwnership
}
