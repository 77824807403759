import { useCollectiveContext } from 'context/CollectiveContext'

function NotAcceptingNewMembersNotice() {
  const { collective } = useCollectiveContext()

  return (
    <div className="rounded-md bg-red-100 px-4 py-3">
      <p className="text-sm font-medium text-red-800">{`Currently, ${collective.name} is not accepting new members`}</p>
    </div>
  )
}

export default NotAcceptingNewMembersNotice
