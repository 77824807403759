import Button from 'components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { useCollectiveContext } from 'context/CollectiveContext'
import BaseModal from 'components/modals/BaseModal'
import { TTxUiState } from 'lib/collectives/helpers'
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useWeb3 } from 'context/Web3Context'
import { Web3AuthPromptModal } from 'components/Collectives/modals/Web3AuthPromptModal'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { noop } from 'lodash'
import { DemoTooltipText } from 'context/CollectiveDemoContext'
import Typography from 'components/common/Typography'

interface IProps {
  title: string
  titleVariant?: string
  state: Maybe<TTxUiState>
  error?: string
  disableConfirm?: boolean
  onConfirm?: Callback
  labels?: KVMap<string, TTxUiState>
  confirmLabel?: string
  requireWeb3?: boolean
  modalClassName?: string
  footerButtons?: React.ReactNode[]
  zIndex?: number
  ignoreDemoMode?: boolean // forces uses parent confirm functions
  hideButtons?: boolean
  onClose?: Callback
  cancelButtonLabel?: string
  hideConfirmButton?: boolean
  disableBackdropClick?: boolean
  guardOnly?: boolean
  children?: React.ReactNode
}

export const StateNotice: React.FC<{
  label: string
  children?: React.ReactNode
}> = ({ label, children }) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-5 items-center ">
        <Typography size="xs" color="gray" fontWeight="light" component="p">
          {label}
        </Typography>

        {children}
      </div>
    </div>
  )
}

const Web3ActionModal: React.FC<IProps> = ({
  title,
  state,
  requireWeb3 = true,
  labels = {},
  disableConfirm,
  confirmLabel = 'Confirm',
  onConfirm = noop,
  children,
  modalClassName,
  footerButtons = [],
  zIndex = 3000,
  ignoreDemoMode = false,
  hideButtons = false,
  onClose,
  cancelButtonLabel = 'Cancel',
  hideConfirmButton = false,
  disableBackdropClick = false,
  guardOnly = false
}) => {
  const { signer, connecting } = useWeb3()
  const { demoMode } = useCollectiveContext()

  const inFlow = state !== null && state !== undefined

  if (connecting) {
    return null
  } else if (requireWeb3 && !signer && !demoMode) {
    return <Web3AuthPromptModal zIndex={zIndex} onClose={onClose} />
  } else if (guardOnly) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <BaseModal
      classNames={modalClassName}
      zIndex={zIndex}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
    >
      <div className="p-5 flex flex-1 justify-center content-center w-full max-sm:w-screen max-sm:h-screen flex-col gap-6 overflow-hidden h-full">
        <div className="flex w-full">
          <Typography size="xl" fontWeight="bold">
            {title}
          </Typography>
        </div>

        {state === TTxUiState.WAITING_FOR_PROVIDER && (
          <StateNotice
            label={
              labels[TTxUiState.WAITING_FOR_PROVIDER] ||
              `Waiting on your wallet provider...`
            }
          >
            <FontAwesomeIcon spin icon={faSpinner} size={'2x'} />
          </StateNotice>
        )}
        {state === TTxUiState.WAITING_FOR_NETWORK && (
          <StateNotice
            label={
              labels[TTxUiState.WAITING_FOR_NETWORK] ||
              `Waiting for your transaction to clear the network...`
            }
          >
            <FontAwesomeIcon spin icon={faSpinner} size={'2x'} />
          </StateNotice>
        )}
        {state === TTxUiState.WAITING_FOR_DELEGATES && (
          <StateNotice
            label={
              labels[TTxUiState.WAITING_FOR_DELEGATES] ||
              `Waiting for your delegation to clear the network... Please don't close this modal`
            }
          >
            <FontAwesomeIcon spin icon={faSpinner} size={'2x'} />
          </StateNotice>
        )}
        {state === TTxUiState.WAITING_FOR_APPROVAL && (
          <StateNotice
            label={
              labels[TTxUiState.WAITING_FOR_APPROVAL] ||
              `Waiting for your token approval to clear the network... Please don't close this modal`
            }
          >
            <FontAwesomeIcon spin icon={faSpinner} size={'2x'} />
          </StateNotice>
        )}
        {state === TTxUiState.TX_FAILURE && (
          <StateNotice
            label={labels[TTxUiState.TX_FAILURE] || `Your transaction failed.`}
          >
            <FontAwesomeIcon icon={faTimes} className="text-red-600" />
          </StateNotice>
        )}
        {state === TTxUiState.TX_SUCCESS && (
          <StateNotice
            label={
              labels[TTxUiState.TX_SUCCESS] ||
              `You've successfully completed this transaction.`
            }
          >
            <FontAwesomeIcon icon={faCheck} className="text-green-600" />
          </StateNotice>
        )}
        {!inFlow ? children : null}

        {!hideButtons && (
          <div className="w-full">
            <div className="flex justify-center content-center gap-2">
              {footerButtons}

              <Button
                color="outline"
                onClick={onClose}
                label={state ? `Dismiss` : cancelButtonLabel}
              />

              {!inFlow && !hideConfirmButton && (
                <StyledTooltip
                  arrow
                  title={demoMode && !ignoreDemoMode ? DemoTooltipText : ''}
                >
                  <Button
                    rounded="md"
                    color="blue"
                    onClick={!demoMode || ignoreDemoMode ? onConfirm : noop}
                    label={confirmLabel}
                    disabled={!demoMode && (!!state || disableConfirm)}
                  />
                </StyledTooltip>
              )}
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  )
}

export default Web3ActionModal
