import {
  useRequestCollectiveMembershipMutation,
  CollectiveProposalCommand
} from 'graphql/generated'
import { useControlledInput, useQueryErrorHandler } from 'hooks'
import BaseModal from 'components/modals/BaseModal'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useState } from 'react'
import { faCoins, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import { useAddProposal } from 'hooks/collectives/demo/useAddProposal'
import Button from 'components/Button'
import TextField from 'components/common/TextField'

export function RequestCollectiveMembershipModal({
  onClose
}: {
  onClose?: Callback
}) {
  const { demoMode, collective } = useCollectiveContext()
  const { currentUser } = useCollectiveDemoContext()
  const addProposals = useAddProposal()
  const [notes, setNotes] = useControlledInput('')

  const [demoButtonLoad, setDemoButtonLoad] = useState<boolean>(false)

  const onError = useQueryErrorHandler(`Failed to request DAO membership`)

  const [requestMembership, { loading }] =
    useRequestCollectiveMembershipMutation({
      variables: {
        notes: notes,
        collectiveId: collective.id
      },
      onError: err => {
        window.analytics?.trackAll(`Membership - Request - Error`)
        onError(err)
      },
      onCompleted: () => {
        window.analytics?.trackAll(`Membership - Request - Complete`)
        onClose?.()
      }
    })

  if (!collective.parameters.enableNewMembershipRequests) {
    return (
      <BaseModal onClose={onClose}>
        <div className="relative flex flex-col justify-center items-center px-10 pt-8 pb-4 space-y-2">
          <div
            className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <FontAwesomeIcon
            icon={faCoins}
            className="text-red-400"
            size={'3x'}
          />

          <div className="py-10">
            <p className="text-center font-medium text-xl pb-2">
              {`Memberships are Closed`}
            </p>

            <p className="text-center font-light text-sm pb-6">
              {`This DAO is not accepting any new members at this time.`}
            </p>
          </div>

          <div className="w-full">
            <div className="flex justify-center">
              <Button color="outline" onClick={onClose} label={`Dismiss`} />
            </div>
          </div>
        </div>
      </BaseModal>
    )
  }

  //TODO: should we make user accept Tos here ?

  return (
    <BaseModal onClose={onClose}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 md:ml-0 sm:text-left">
          <h3
            className="text-lg leading-6 font-medium text-gray-900"
            id="modal-headline"
          >
            {`Request to Join ${collective.name}`}
          </h3>

          <div className="pt-6">
            <TextField
              label={`Tell the DAO about yourself and why you'd like to join`}
              multiple
              minRows={4}
              value={notes}
              onChange={setNotes}
              disabled={loading}
              horizantalFullWidth
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-4 px-6 flex space-x-2 justify-end">
        <Button color="outline" onClick={onClose} label={`Dismiss`} />

        {!demoMode ? (
          <Button
            onClick={() => requestMembership()}
            disabled={loading}
            label={`Submit Request`}
          />
        ) : (
          <Button
            loading={demoButtonLoad}
            onClick={() => {
              setDemoButtonLoad(true)
              setTimeout(() => {
                addProposals({
                  commands: [
                    {
                      __typename: 'CollectiveProposalAddMemberCommand',
                      memberAddress: currentUser.address
                    }
                  ] as CollectiveProposalCommand[],
                  title: `New Member: ${currentUser.displayName || ''}`,
                  description: notes || 'trying to join',
                  useOrigin: notes || 'trying to join'
                })
                setDemoButtonLoad(false)
                onClose?.()
              }, 500)
            }}
            disabled={loading}
            label={`Submit Request`}
          />
        )}
      </div>
    </BaseModal>
  )
}
