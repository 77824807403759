import { faLink, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/Button'
import TrackableClick from 'components/common/TrackableClick'
import Typography from 'components/common/Typography'
import { useState, useCallback } from 'react'

function LinkShareContent({
  titleContent,
  extraCopyText = '',
  url,
  trackEventProps,
  onClose
}: {
  titleContent: string | React.ReactElement
  trackEventProps: object
  extraCopyText?: string
  url: string
  onClose?: Callback
}) {
  const [copied, setCopied] = useState<Maybe<boolean>>(null)
  const copyToClipBoard = useCallback(async () => {
    try {
      await window.navigator.clipboard.writeText(extraCopyText + url)
      setCopied(true)
    } catch (err) {
      console.error(err)
      setCopied(false)
    }

    setTimeout(() => setCopied(null), 2000)
  }, [extraCopyText, url])

  return (
    <div className="relative py-5 px-5">
      {onClose && (
        <div
          className="absolute top-4 right-5 cursor-pointer"
          onClick={onClose}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="relative text-lg text-gray-500"
          />
        </div>
      )}

      {titleContent}

      <div className="flex rounded-md shadow-sm max-w-4xl border border-gray-200">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none inset-y-0 left-0 flex items-center px-3 border border-y-0 border-l-0 focus:border-0  border-r-gray-200 ">
            <FontAwesomeIcon
              icon={faLink}
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <span className="border-r-0 text-gray-500 pr-4 pl-2 pt-3">
            <Typography size="sm" color="gray">
              {url}
            </Typography>
          </span>
        </div>
        <div className="relative -ml-px inline-flex items-center space-x-2 p-1">
          <TrackableClick event="Invite Link Copied" props={trackEventProps}>
            <Button
              onClick={copyToClipBoard}
              color={
                copied === true ? 'green' : copied === false ? 'red' : 'blue'
              }
              size="sm"
              showFocusRing={false}
            >
              <span className="mx-4">
                {copied === true
                  ? 'Copied'
                  : copied === false
                  ? 'Failed to Copy'
                  : 'Copy'}
              </span>
            </Button>
          </TrackableClick>
        </div>
      </div>
    </div>
  )
}

export default LinkShareContent
