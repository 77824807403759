import Link from 'next/link'
import { BigNumber } from 'lib/BigInt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faTimes, faUsers } from '@fortawesome/pro-regular-svg-icons'
import useToasts from 'hooks/useToasts'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useCallback, useState } from 'react'
import {
  TosAgreementType,
  useAgreeToCollectiveTosMutation,
  useCollectiveTosQuery
} from 'graphql/generated'
import useDepositEther from 'hooks/collectives/useDepositEther'
import {
  parseWeb3ErrorMessage,
  TTxUiState,
  TX_STATUSES,
  getWeiBN
} from 'lib/collectives/helpers'
import Web3ActionModal from 'components/Collectives/modals/Web3ActionModal'
import EtherInputField from 'components/Collectives/Common/EtherInputField'
import BaseModal from 'components/modals/BaseModal'
import { useCurrentUser } from 'hooks'
import { CollectiveTosModal } from './CollectiveTosModal'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import { useUpdateCollectiveTokenOwnership } from 'hooks/collectives/demo/useUpdateCollectiveTokenOwnership'
import { useWalletCheck } from 'hooks/collectives/useWalletCheck'
import { noop } from 'lodash'
import WalletCheckInfo from '../Common/WalletCheckInfo'
import Button from 'components/Button'
import { Toggle } from 'components/common'

export function CollectiveDepositModal({ onClose }: { onClose?: Callback }) {
  const { addToast } = useToasts()
  const [iUnderstand, setIUnderstand] = useState(false)
  const { showTos, demoMode, collective } = useCollectiveContext()

  const { collectiveDemo } = useCollectiveDemoContext()
  const updateCollectiveTokenOwnership = useUpdateCollectiveTokenOwnership()

  const { deposit } = useDepositEther({ collective })

  const minEthContributionInWeiBN = getWeiBN(
    collective.parameters.minEthContribution
  )
  const [amountInWei, setAmountInWei] = useState<BigNumber>(
    minEthContributionInWeiBN
  )
  const [validAmount, setValidAmount] = useState(true)
  const [state, setState] = useState<Maybe<TTxUiState>>(null)

  const [saveAgreement] = useAgreeToCollectiveTosMutation({
    variables: {
      type: TosAgreementType.BRIEF
    }
  })
  const onUnderstand = () => {
    if (!demoMode) {
      saveAgreement()
    }
    setIUnderstand(true)
  }

  const doDeposit = useCallback(async () => {
    if (demoMode && collectiveDemo) {
      updateCollectiveTokenOwnership(amountInWei)

      onClose?.()
      return
    }

    try {
      setState(TTxUiState.WAITING_FOR_PROVIDER)

      const { txStatus } = await deposit({
        value: amountInWei,
        onCall: _state => setState(_state)
      })

      const isSuccess = txStatus === TX_STATUSES.succeeded
      setState(isSuccess ? TTxUiState.TX_SUCCESS : TTxUiState.TX_FAILURE)
      window.analytics.trackAll(
        `Transfer - Contribute - ${isSuccess ? 'Complete' : 'Error'}`,
        {
          amount: amountInWei.formatEther(),
          symbol: 'ETH'
        }
      )
    } catch (err) {
      addToast(parseWeb3ErrorMessage(err), {
        appearance: 'warning'
      })
      setState(null)
    }
  }, [
    addToast,
    amountInWei,
    collectiveDemo,
    demoMode,
    deposit,
    onClose,
    updateCollectiveTokenOwnership
  ])

  const { showWalletNotice } = useWalletCheck(collective)

  const { id: loggedInUserId } = useCurrentUser()

  const { data } = useCollectiveTosQuery({
    variables: {
      collectiveId: collective.id
    }
  })
  const userDataHasLoaded = !!loggedInUserId
  const promptFullTos =
    userDataHasLoaded && !data?.collectiveTos.isSigned && !demoMode

  if (promptFullTos) {
    return <CollectiveTosModal onClose={onClose} />
  } else if (!iUnderstand) {
    return (
      <BaseModal onClose={onClose}>
        <div className="relative flex flex-col justify-center items-center px-10 pt-8 pb-4 space-y-2">
          <div
            className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <FontAwesomeIcon
            icon={faUsers}
            className="text-blue-400"
            size={'3x'}
          />

          <div className="py-4">
            <p className="text-center font-medium text-xl pb-2">
              {`DAOs have risks!`}
            </p>

            <div className="bg-gray-100 border border-gray-200 rounded-lg p-2 max-h-60 overflow-auto mb-4">
              <p className="text-sm font-light text-text-primary pb-4">
                Welcome to Upstream DAOs! In order to participate in a DAO, you
                must acknowledge and specifically agree to each of the following
                individual terms:
              </p>

              <ul className="list-disc list-inside">
                <li className="text-sm font-light text-text-primary pb-2">
                  DAOs are not to be utilized for investment purposes, nor are
                  they intended to be used for the purchase of financial
                  products. You may not use a DAO to conduct any activity which
                  is illegal in your jurisdiction.
                </li>
                <li className="text-sm font-light text-text-primary pb-2">
                  Funds contributed to a DAO will be collectively held by the
                  DAO in a multi-signature, non-custodial wallet, and not by
                  Upstream. Upstream does not have the power to refund any funds
                  contributed to a DAO.
                </li>
                <li className="text-sm font-light text-text-primary pb-2">
                  Cryptocurrency is largely unregulated and inherently risky.
                  Participation in a DAO is at your own risk. Upstream is not
                  responsible for any losses you may incur, including, but not
                  limited to, those resulting from the actions of other users in
                  the DAO.
                </li>
                <li className="text-sm font-light text-text-primary pb-2">
                  The DAO, and not Upstream or any one individual, makes
                  decisions on behalf of the group. Accordingly, the DAO may
                  make decisions made that you do not agree with, which can
                  result in a lack of liquidity or the loss of your digital
                  assets. You will be bound by such decisions, even if you
                  disagree with them. Before contributing any funds, you are
                  responsible for making sure you understand the technology
                  utilized by the DAO and associated risks.
                </li>
                <li className="text-sm font-light text-text-primary pb-2">
                  You are waiving your rights to assert a claim against Upstream
                  in a court of law in front of a judge and/or jury. Any
                  disputes you have with Upstream can only be resolved through
                  binding arbitration on an individual basis.
                </li>
              </ul>
            </div>

            {!demoMode && (
              <p className="text-center font-light text-sm pb-6">
                <span
                  className="text-blue cursor-pointer"
                  onClick={() => showTos()}
                >
                  {`View our full Terms of Service for more information.`}
                </span>
              </p>
            )}

            <div className="flex flex-row justify-center">
              <Toggle
                checked={false}
                onChange={onUnderstand}
                label="I understand"
              />
            </div>
          </div>
        </div>
      </BaseModal>
    )
  } else if (!collective.parameters.enableDeposits) {
    return (
      <BaseModal onClose={onClose}>
        <div className="relative flex flex-col justify-center items-center px-10 pt-8 pb-4 space-y-2">
          <div
            className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <FontAwesomeIcon
            icon={faCoins}
            className="text-red-400"
            size={'3x'}
          />

          <div className="py-10">
            <p className="text-center font-medium text-xl pb-2">
              {`Contributions are Disabled`}
            </p>

            <p className="text-center font-light text-sm pb-6">
              {`This DAO is not accepting any Ether contributions at this moment. Please create a proposal to re-enable contributions.`}
            </p>
          </div>

          <div className="w-full">
            <div className="flex justify-end">
              <Button onClick={onClose} color="outline" label={`Dismiss`} />
            </div>
          </div>
        </div>
      </BaseModal>
    )
  } else if (
    collective.parameters.gateDeposits &&
    !collective.membership?.approved
  ) {
    return (
      <BaseModal onClose={onClose}>
        <div className="relative flex flex-col justify-center items-center px-10 pt-8 pb-4 space-y-2">
          <div
            className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <FontAwesomeIcon
            icon={faCoins}
            className="text-red-400"
            size={'3x'}
          />

          <div className="py-10">
            <p className="text-center font-medium text-xl pb-2">
              {`Contributions are Gated`}
            </p>

            <p className="text-center font-light text-sm pb-6">
              {`This DAO is only accepting contributions from DAO members.`}
            </p>
          </div>

          <div className="w-full">
            <div className="flex justify-end">
              <Button onClick={onClose} color="outline" label={`Dismiss`} />
            </div>
          </div>
        </div>
      </BaseModal>
    )
  } else if (showWalletNotice && !demoMode) {
    return (
      <Web3ActionModal
        title={`Contribute ETH`}
        state={undefined}
        disableConfirm={true}
        onConfirm={noop}
        onClose={onClose}
      >
        <WalletCheckInfo
          collective={collective}
          className="bg-red-50 hover:bg-red-100 px-5 py-3 rounded-lg my-3"
        />
      </Web3ActionModal>
    )
  }

  return (
    <Web3ActionModal
      title={`Contribute${demoMode ? ' Demo ETH' : ' ETH'}`}
      state={state}
      disableConfirm={!validAmount}
      onConfirm={doDeposit}
      onClose={onClose}
      ignoreDemoMode
      labels={{
        [TTxUiState.TX_SUCCESS]: `You've successfully contributed ${amountInWei.formatEther()} Ether to the DAO`
      }}
    >
      <div className="w-full">
        <div>
          <EtherInputField
            label={`Enter the amount you wish to contribute`}
            initialValueInWei={amountInWei}
            onChangeInWei={setAmountInWei}
            disabled={state !== null}
            onValidityChange={setValidAmount}
            minWei={minEthContributionInWeiBN}
          />
        </div>

        <p className="text-sm font-light pt-2">
          {`Contribution fee of ${collective.parameters.depositFeeInPercentage}% applies. `}
          <Link
            className="text-blue"
            target="_blank"
            href="https://help.upstreamapp.com/en/collections/3222695-upstream-collective-faqs"
          >
            {`Learn more about this fee`}
          </Link>
          {`.`}
        </p>
      </div>
    </Web3ActionModal>
  )
}
