import { UpstreamCollectiveToken__factory } from '@upstreamapp/upstream-dao'
import { Collective } from 'graphql/generated'
import { useWeb3 } from 'context/Web3Context'
import { useMemo } from 'react'
import { BigNumber } from 'lib/BigInt'
import { TTxUiState } from 'lib/collectives/helpers'

type TCollective = Pick<Collective, 'id' | 'address'>

function useDepositEther({ collective }: { collective: TCollective }) {
  const { signer } = useWeb3()
  const dao = useMemo(
    () =>
      signer
        ? UpstreamCollectiveToken__factory.connect(collective.address, signer)
        : undefined,
    [signer, collective.address]
  )

  const deposit: Web3Action<{ value: BigNumber } & IWeb3ActionOptions> =
    async function ({ value, onCall }) {
      if (!dao) {
        throw new Error(`Please sign in with Web3 wallet to continue`)
      }

      const transaction = await dao.deposit({
        value: value.toString()
      })
      onCall(TTxUiState.WAITING_FOR_NETWORK)
      const { status: txStatus } = await transaction.wait()

      return { txStatus }
    }

  return {
    deposit
  }
}

export default useDepositEther
