import { twMerge } from 'tailwind-merge'

interface IProps {
  className?: string
  children?: React.ReactNode
}

function ScrollToTop({ className, children }: IProps) {
  const onClick = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <div className={twMerge('cursor-pointer', className)} onClick={onClick}>
      {children}
    </div>
  )
}

export default ScrollToTop
