import { twMerge } from 'tailwind-merge'

const ContentWrapper: React.FC<{
  classNames?: string
  children?: React.ReactNode
}> = ({ children, classNames }) => {
  return (
    <div className={twMerge('bg-white shadow sm:rounded-lg', classNames)}>
      {children}
    </div>
  )
}
export default ContentWrapper
