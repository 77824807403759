import { useCallback } from 'react'
import BaseModal from 'components/modals/BaseModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import Button from 'components/Button'
import { useWeb3 } from 'context/Web3Context'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'

export function Web3AuthPromptModal({
  onLogin,
  zIndex,
  onClose
}: {
  onLogin?: Callback
  onClose?: Callback
  zIndex?: number
}) {
  const { openSelectWallet } = useWeb3()
  const doAuth = useCallback(async () => {
    await openSelectWallet()
    onLogin?.()
  }, [openSelectWallet, onLogin])

  return (
    <BaseModal zIndex={zIndex} onClose={onClose}>
      <div className="relative flex flex-col justify-center items-center px-10 pt-8 pb-4 space-y-2">
        <div
          className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>

        <FontAwesomeIcon
          icon={faEthereum}
          className="text-blue-400"
          size={'3x'}
        />

        <div className="py-10">
          <p className="text-center font-medium text-xl">
            {`Sign in with your Web3 wallet`}
          </p>

          <p className="text-center font-light text-sm">
            {`This action requires you to authenticate your Web3 wallet.`}
          </p>
        </div>

        <Button
          label={'Sign-in with Wallet'}
          onClick={doAuth}
          className="w-full"
          rounded={'full'}
          size={'lg'}
        />
      </div>
    </BaseModal>
  )
}
