import { faPiggyBank } from '@fortawesome/pro-regular-svg-icons'
import Button from 'components/Button'
import { useCollectiveContext } from 'context/CollectiveContext'
import { CollectiveDepositModal } from '../modals/CollectiveDepositModal'
import ModalTrigger from '../../common/ModalTrigger'

function OpenToContributionsNotice() {
  const { collective } = useCollectiveContext()

  return (
    <div className="w-full flex justify-between pt-5 md:px-10">
      <div className="text-xl font-extrabold tracking-tight text-gray-700 space-y-0.5">
        <p>{`${collective.name} is open for contributions.`}</p>
        <p>{`Deposit ETH to join the DAO.`}</p>
      </div>

      <ModalTrigger
        trigger={onClick => (
          <Button
            label="Deposit"
            icon={faPiggyBank}
            onClick={onClick}
            size="xl"
          />
        )}
      >
        <CollectiveDepositModal />
      </ModalTrigger>
    </div>
  )
}

export default OpenToContributionsNotice
