import Button from 'components/Button'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useJoinExternalCollectiveMutation } from 'graphql/generated'
import { useQueryErrorHandler } from 'hooks'
import { getEtherscanUrlForCollective } from 'lib/collectives/helpers'

function ExternalCollectiveJoinNotice() {
  const { collective, showTos } = useCollectiveContext()

  const onError = useQueryErrorHandler(
    'Failed to join DAO. Please try again later.'
  )

  const [joinCollective, { loading }] = useJoinExternalCollectiveMutation({
    variables: {
      collectiveId: collective.id
    },
    onError,
    onCompleted: () => {
      window.analytics?.trackAll(`Membership - Join External - Complete`)
    }
  })

  const checkTosAndJoin = () => {
    showTos(agreed => {
      if (agreed) {
        joinCollective()
      }
    })
  }

  return (
    <div className="w-full flex justify-between pt-5 md:px-10">
      <div className="text-xl font-extrabold tracking-tight text-gray-900 space-y-0.5">
        <p>
          {'Do you hold the '}
          <a
            href={getEtherscanUrlForCollective(collective, 'token')}
            className="border-b border-dashed border-gray-900 hover:text-blue-600 hover:border-blue-600"
            target="_blank"
            rel="noreferrer"
          >
            {collective.tokens[0].symbol}
          </a>
          {' token?'}
        </p>
        <p className="text-blue-600">
          Your NFT unlocks entry to {collective.name}.
        </p>
      </div>

      <Button
        label={`Join ${collective.name}`}
        onClick={() => checkTosAndJoin()}
        loading={loading}
        disabled={loading}
      />
    </div>
  )
}

export default ExternalCollectiveJoinNotice
