import { Collective } from 'graphql/generated'
import useCurrentUser from 'hooks/useCurrentUser'
import useIsEthNetworkDifferent from './useIsEthNetworkDifferent'
import { useWeb3 } from 'context/Web3Context'

type TCollective = Pick<Collective, 'id' | 'network'> & {
  membership?: Maybe<{
    id: string
  }>
}

export function useWalletCheck(collective?: Maybe<TCollective>) {
  const { collectiveUser } = useCurrentUser()
  const { signerAddress } = useWeb3()
  const wrongWalletNetwork = useIsEthNetworkDifferent()

  const connectedCollectiveUser = collectiveUser?.find(
    u => u.address.toLowerCase() === signerAddress?.toLowerCase()
  )

  const collectiveUserWithCollectiveAccess = collective
    ? connectedCollectiveUser?.collectives.find(c => c.id === collective.id)
    : null

  const walletsThatHaveAccessToCollective = collective
    ? collectiveUser?.filter(u =>
        u.collectives.find(c => c.id === collective.id)
      )
    : null

  const walletHasAccess = !!collectiveUserWithCollectiveAccess
  const showWalletNotice =
    // show notice for "Wrong Chain Network"
    (!!collective && wrongWalletNetwork) ||
    // show notice for "Wrong Wallet Selected"
    (!!collective &&
      !walletHasAccess &&
      // .. but not in non-gated collectives landing pages
      !!collective.membership)

  return {
    walletsThatHaveAccessToCollective,
    walletHasAccess,
    wrongWalletNetwork,
    showWalletNotice
  }
}
