import { RequestCollectiveMembershipModal } from '../modals/RequestCollectiveMembershipModal'
import { motion } from 'framer-motion'
import ModalTrigger from '../../common/ModalTrigger'
import Button from 'components/Button'
import { useCollectiveContext } from 'context/CollectiveContext'
import {
  CollectiveMembershipRequest,
  CollectiveMembershipRequestStatus
} from 'graphql/generated'
import { humanTime } from 'lib/datetime'

type MembershipRequestType = Pick<
  CollectiveMembershipRequest,
  'status' | 'createdAt'
>

type Props = {
  membershipRequestStatus: Maybe<MembershipRequestType>
}

function RequestToJoinNotice({ membershipRequestStatus }: Props) {
  const { collective, demoMode } = useCollectiveContext()

  return (
    <ModalTrigger
      trigger={onClick => (
        <div className="w-full flex justify-between items-center pt-5 md:px-10">
          <div className="text-xl font-extrabold tracking-tight text-gray-900 space-y-0.5">
            {membershipRequestStatus?.status ===
              CollectiveMembershipRequestStatus.REJECTED && (
              <p className="bg-red-200">
                {`Your request to join ${collective.name} ${humanTime(
                  membershipRequestStatus.createdAt
                )} was rejected.`}
              </p>
            )}

            <p>Want to join?</p>
            <p className="text-blue-600">Request to join {collective.name}.</p>
          </div>

          <motion.div
            animate={{
              scale: demoMode ? [1, 1.1, 1] : [1, 1]
            }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 70,
              duration: 1.5,
              repeat: Infinity
            }}
          >
            <Button
              label="Request to Join"
              onClick={onClick}
              className="h-14"
            />
          </motion.div>
        </div>
      )}
    >
      <RequestCollectiveMembershipModal />
    </ModalTrigger>
  )
}

export default RequestToJoinNotice
