import { useCollectiveContext } from 'context/CollectiveContext'
import {
  useCollectiveDemoContext,
  ProposalInitData,
  ProposalTypeFull
} from 'context/CollectiveDemoContext'
import {
  CollectiveProposalCommand,
  CollectiveProposalStatus,
  CollectiveMembershipRequestStatus,
  CollectiveVotesQueryResult,
  CollectiveProposalType
} from 'graphql/generated'
import moment from 'moment'
import { useCallback } from 'react'

export const useAddProposal = () => {
  const { demoMode } = useCollectiveContext()

  const {
    collectiveDemo,
    collectiveProposals,
    actionableCommands,
    currentUser,
    updateDemoCache,
    newProposals,
    setNewProposals,
    setDemoActiveProposal,
    setCollectiveProposals
  } = useCollectiveDemoContext()

  const addProposals = useCallback(
    ({
      commands,
      title,
      description = '',
      useOrigin,
      durationHours = 1
    }: ProposalInitData): ProposalTypeFull => {
      if (!demoMode) {
        return
      }

      const id = `proposalForDemo${newProposals.size + 1}`

      if (!collectiveDemo || newProposals.has(id)) {
        return undefined
      }

      const newCollectiveDemo = { ...collectiveDemo }

      const newProposalCommands = commands.reduce((arr, type) => {
        const allowedToAddCommand = actionableCommands.find(
          c => c.__typename === type.__typename
        )

        if (allowedToAddCommand) {
          arr.push(type)
        }
        return arr
      }, [] as CollectiveProposalCommand[])

      const newProposal = {} as NonNullable<ProposalTypeFull>
      newProposal.canVote = true
      newProposal.isVotable = true
      newProposal.createdAt = new Date()
      newProposal.status = CollectiveProposalStatus.ACTIVE
      newProposal.title = title
      newProposal.descriptionHtml = description
      newProposal.totalVoters = '0'
      newProposal.totalVotes = '0'
      newProposal.votesAgainst = '0'
      newProposal.votesFor = '0'
      newProposal.id = id
      newProposal.isBatch = false
      newProposal.myVote = undefined
      newProposal.proposedBy = currentUser.membership
      newProposal.execution = undefined
      newProposal.commands = newProposalCommands
      newProposal.signatures = []
      newProposal.quorumNeeded = 1
      newProposal.startTime = moment().add(2, 'seconds').toDate()
      newProposal.endTime = moment().add(durationHours, 'hour').toDate()
      newProposal.execution = null
      newProposal.votesAgainst = '0'
      newProposal.votesFor = '0'
      newProposal.totalVotes = '0'
      newProposal.totalVoters = '0'
      newProposal.canVote = true
      newProposal.status = CollectiveProposalStatus.ACTIVE
      newProposal.batchedProposals = []
      newProposal.signatures = []
      newProposal.collective = newCollectiveDemo
      newProposal.type = CollectiveProposalType.COMMANDS

      // once user requests membership

      if (useOrigin) {
        newProposal.origin = {
          __typename: 'CollectiveMembershipRequest',
          id: '',
          user: currentUser.membership.user,
          notes: useOrigin
        }

        newCollectiveDemo.membershipRequest = {
          __typename: 'CollectiveMembershipRequest',
          createdAt: new Date(),
          id: '',
          status: CollectiveMembershipRequestStatus.PENDING
        }
      }

      const newCollectiveProposalsData = [
        {
          proposal: newProposal,
          votes: {
            __typename: 'CollectiveProposalVoteConnection',
            edges: [],
            pageInfo: {
              __typename: 'PageInfo',
              hasNextPage: false,
              hasPreviousPage: false,
              startCursor: undefined,
              endCursor: undefined
            }
          } as NonNullable<
            CollectiveVotesQueryResult['collectiveProposal']
          >['votes'],
          signators: []
        },
        ...collectiveProposals
      ]

      setCollectiveProposals(newCollectiveProposalsData)

      newCollectiveDemo.proposals.edges = [
        { node: newProposal, __typename: 'CollectiveProposalEdge', cursor: '' },
        ...(newCollectiveDemo?.proposals.edges || [])
      ]

      const newProposalIds = new Set(newProposals)
      newProposalIds.add(id)

      setNewProposals(newProposalIds)

      setDemoActiveProposal(newProposal)

      newCollectiveDemo.totalProposals = newCollectiveDemo.totalProposals + 1

      const addMemberCommand = commands.find(
        com => com.__typename === 'CollectiveProposalAddMemberCommand'
      ) as {
        __typename: 'CollectiveProposalAddMemberCommand'
        memberAddress: string
      }

      if (addMemberCommand) {
        newCollectiveDemo.totalMembershipRequests =
          newCollectiveDemo.totalMembershipRequests + 1
      }

      updateDemoCache(newCollectiveDemo)

      return newProposal
    },
    [
      actionableCommands,
      collectiveDemo,
      collectiveProposals,
      currentUser,
      demoMode,
      newProposals,
      setCollectiveProposals,
      setDemoActiveProposal,
      setNewProposals,
      updateDemoCache
    ]
  )

  return addProposals
}
